import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";

const MainNav = ({ data, user }) => {
  const path = window.location.pathname;
  const routes = data.map((route) => ({
    href: `/category/${route.mainMealID}?Profile=${user.Profile}&BrId=${user.BranchId}&MobNo=${user.Mobile}&CusName=${user.CustomerName}&Lang=${user.Language}&CustNumber=${user.CustomerNumber}&ConvId=${user.ConvId}`,
    label: route.mainMealAR,
    active: path === `/category/${route.mainMealID}`,
  }));
  return (
    <nav className="flex flex-wrap justify-center sm:justify-start items-center">
      {routes.map((route) => (
        <NavLink
          key={route.href}
          to={route.href}
          className="text-white mt-5 mb-5 font-semibold hover:opacity-60 transition px-4 py-2 rounded-full bg-red-700 flex items-center justify-center"
          activeClassName="text-white-600"
          style={{ minWidth: "120px" }}
        >
          {route.label}
        </NavLink>
      ))}
    </nav>
  );
};

export default MainNav;