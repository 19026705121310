const getDeliveryInfo = async (Profile, BranchId) => {
  try {
    const response = await fetch(
      `https://telelogx-001-site1.atempurl.com/api/DeliveryCost?BranchId=${BranchId}&Profile=${Profile}`,
      {
        headers: {
          Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYmYiOjE2OTI2Mjk2MjMsImV4cCI6MTY5MjYzMzIyMywiaWF0IjoxNjkyNjI5NjIzfQ.koblKU4ufEsVYZ0wyRVDSrbcIjAlmR-9VUty3xwzj6I`,
        },

        cache: "no-store",
      }
    );
    if (response.ok) {
      const deliveryjson = await response.json();
      return deliveryjson;
    } else return false;
  } catch (error) {
    return false;
  }
};

export default getDeliveryInfo;
