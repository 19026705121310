const getCart = async (profile, BranchId, Mobile, Lang) => {
  try {
    const response = await fetch(
      `https://telelogx-001-site1.atempurl.com/api/Cart?ProfileID=${profile}&BranchId=${BranchId}&Mobile=${Mobile}&Lang=${Lang}`,
      {
        headers: {
          Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYmYiOjE2OTI2Mjk2MjMsImV4cCI6MTY5MjYzMzIyMywiaWF0IjoxNjkyNjI5NjIzfQ.koblKU4ufEsVYZ0wyRVDSrbcIjAlmR-9VUty3xwzj6I`,
        },

        cache: "no-store",
      }
    );
    if (response.ok) {
      const cartjson = await response.json();
      const cart = {
        ordr_Price: cartjson.ordr_Price,
        ordt_OrdersId: cartjson.ordt_OrdersId,
        details: cartjson.details?.map((detail) => ({
          ordt_OrderDetails: detail.ordt_OrderDetails,
          ordt_Price: detail.ordt_Price,
          ordt_Qty: detail.ordt_Qty,
          subMeal: {
            id: detail.subMeal.subMealID,
            category: {
              id: detail.subMeal.mainMeal.mainMealID,
              name: detail.subMeal.mainMeal.mainMealAR,
            },
            name: detail.subMeal.subMealAR,
            price: detail.subMeal.subMealPrice,
           // subMealNewPrice: detail.subMeal.subMealNewPrice,
            isFeatured: false,
            images: detail.subMeal.subMealImgPath.replace(/^~/, ""),
            description: detail.subMeal.description,
          },
        })),
      };
      var total = 0;
      if (cart.ordt_OrdersId != null) {
        cart.details.map((detail) => {
          total += Number(detail.ordt_Price);
        });
        cart.ordr_Price = total.toString();
      }
      return cart;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};
export default getCart;
