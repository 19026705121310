import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import { AppContext } from "./AppContext";
import { useEffect, useState } from "react";
import Navbar from "./components/navbar";
import Footer from "./components/footer";
import Category from "./pages/Category";
import Product from "./pages/Product";
import toast, { Toaster } from "react-hot-toast";
import Cart from "./pages/Cart";
import NotFound from "./pages/NotFound";
import getDiscount from "./api/getDiscount";
import Translate from "./pages/Translate.json";

function App() {
  const moment = require("moment");
  const [content, setContent] = useState();
  const searchParams = new URLSearchParams(document.location.search);
  const [user, setUser] = useState({
    Profile: searchParams.get("Profile"),
    BranchId: searchParams.get("BrId"),
    CustomerName: searchParams.get("CusName"),
    Mobile: searchParams.get("MobNo"),
    Language: searchParams.get("Lang"),
    CustomerNumber: searchParams.get("CustNumber"),
    ConvId: searchParams.get("ConvId"),
    Discount: "",
  });
  useEffect(() => {
    if (user.Language === "ar") {
      setContent(Translate.ar);
    } else if (user.Language === "en") {
      setContent(Translate.en);
    }
  }, []);
  useEffect(() => {
    try {
      // if (content && user.Profile && user.BranchId) {
      //   getDiscount(user.Profile, user.BranchId)
      //     .then((Discountjson) => {
      //       if (Discountjson !== false) {
      //         setUser((prevUser) => ({
      //           ...prevUser,
      //           Discount: Discountjson[0].discountValue,
      //         }));
      //         toast.custom(
      //           (t) => (
      //             <div
      //               className={`${
      //                 t.visible ? "animate-enter" : "animate-leave"
      //               } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
      //             >
      //               <div className="flex-1 w-0 p-4">
      //                 <div className="flex items-start">
      //                   <div className="flex-shrink-0 pt-0.5">
      //                     <img
      //                       className="h-10 w-10 rounded-full"
      //                       src="/discount.png"
      //                       alt=""
      //                     />
      //                   </div>
      //                   <div className="ml-3 flex-1">
      //                     <p className="text-sm font-medium text-red-600">
      //                       {content.DiscountTitle +
      //                         " " +
      //                         parseInt(Discountjson[0].discountValue) +
      //                         " %"}
      //                     </p>
      //                     <p className="mt-1 text-sm text-gray-500">
      //                       {content.DiscountBody +
      //                         " " +
      //                         moment(
      //                           Discountjson[0].endDate,
      //                           "MM/DD/YYYY HH:mm:ss A"
      //                         ).format("DD/MM/YYYY hh:mm A")}
      //                     </p>
      //                   </div>
      //                 </div>
      //               </div>
      //               <div className="flex border-l border-gray-200">
      //                 <button
      //                   onClick={() => toast.dismiss(t.id)}
      //                   className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
      //                 >
      //                   Close
      //                 </button>
      //               </div>
      //             </div>
      //           ),
      //           { duration: 999999 }
      //         );
      //       } else {
      //       }
      //     })
      //     .catch(() => {});
      // }
    } catch (error) {}
  }, [content, user.Profile, user.BranchId]);
  return (
    <div className="App">
      <AppContext.Provider value={{ user }}>
        <BrowserRouter>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/category/:categoryId" element={<Category />}></Route>
            <Route path="/product/:productId" element={<Product />}></Route>
            <Route path="/cart" element={<Cart />}></Route>
            <Route path="/notFound" element={<NotFound />}></Route>
            <Route path="*" element={<NotFound />}></Route>
          </Routes>
          <Footer />
        </BrowserRouter>
      </AppContext.Provider>
    </div>
  );
}

export default App;
