const postMokhatbat = async (accountId,convId,summary) => {
    try {
       const response = await fetch(
        `https://telelogx-001-site1.atempurl.com/api/Mokhatbat/postMessage`,
        {
          cache: "no-store",
          method: "POST",
          headers: {
            Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYmYiOjE2OTI2Mjk2MjMsImV4cCI6MTY5MjYzMzIyMywiaWF0IjoxNjkyNjI5NjIzfQ.koblKU4ufEsVYZ0wyRVDSrbcIjAlmR-9VUty3xwzj6I`,

            "Content-Type": "application/json",
          },
          body: JSON.stringify(
            {
              "accountId": accountId,
              "convId": convId,
              "summary": summary
            }
          ),
        }
      );
      //const productjson = await response.json();
      return response;
    } catch (error) {
      console.error("An error occurred Post Mokhatbat:", error);
      throw error; // Rethrow the error to handle it at a higher level
    }
  };
  export default postMokhatbat;
  