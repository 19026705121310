import { useContext, useEffect, useState } from "react";
import { AppContext } from "../AppContext";
import getDeliveryInfo from "../api/getDeliveryInfo";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Currency from "./currency";
import toast from "react-hot-toast";
import Translate from "./Translate.json";
import getOrderSummary from "../api/getOrderSummary";
import postMokhatbat from "../api/postMokhatbat";
import getAccountId from "../api/getAccountID";
import AsyncSelect from 'react-select/async';

const Summary = ({ TotalPrice, ItemsLength, OrderID, cartFunction }) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { user } = useContext(AppContext);
  const [DeliveryInfo, setDeliveryInfo] = useState();
  const [language, setLanguage] = useState(user.Language);
  const [content, setContent] = useState(Translate.en);
  const [isClearable, setIsClearable] = useState(false);
  const [isSearchable, setIsSearchable] = useState(true);

  useEffect(() => {
    if (language == "ar") {
      setContent(Translate.ar);
    } else if (language == "en") {
      setContent(Translate.en);
    }
  }, []);

  useEffect(() => {
    getDeliveryInfo(user.Profile, user.BranchId).then((res) => {
      if (res !== false) {
        setDeliveryInfo(res);
      } else navigate("/notFound");
    });

    if (searchParams.get("success")) {
      toast.success(content.ConfirmMessage);
      cartFunction();
    }

    if (searchParams.get("canceled")) {
      toast.error("Something went wrong.");
    }
  }, [searchParams, user.Profile, user.BranchId]);

  const onCheckout = async () => {
     // Get the value of the textarea
  const textareaValue = document.getElementById("notes").value;
  
  // Determine the value of notes based on the content of the textarea
  const notes = textareaValue.trim() ? textareaValue : "-";
    if (
      (DeliveryDropDown !== "" && DeliveryType === "Delivery") ||
      DeliveryType === "From Branch"
    ) {
      const response = await fetch(
        `https://telelogx-001-site1.atempurl.com/api/Cart/Checkout`,
        {
          cache: "no-store",
          method: "POST",
          headers: {
            Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYmYiOjE2OTI2Mjk2MjMsImV4cCI6MTY5MjYzMzIyMywiaWF0IjoxNjkyNjI5NjIzfQ.koblKU4ufEsVYZ0wyRVDSrbcIjAlmR-9VUty3xwzj6I`,

            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            orderID: OrderID,
            areaId: DeliveryDropDown,
            orderType: DeliveryType,
            paymentType: selectedPayment,
            notes: notes,
          }),
        }
      );
      navigate(
        `/cart?Profile=${searchParams.get("Profile")}&BrId=${searchParams.get(
          "BrId"
        )}&MobNo=${searchParams.get("MobNo")}&CusName=${searchParams.get(
          "CusName"
        )}&Lang=${searchParams.get("Lang")}&CustNumber=${searchParams.get(
          "CustNumber"
        )}&ConvId=${searchParams.get("ConvId")}&${
          response.ok ? "success=1" : "canceled=0"
        }`
      );
      //ORDER SUMMARY
      const summaryjson = await getOrderSummary(
        user.Profile,
        user.Mobile,
        user.BranchId
      );
      var summaryd = null;
      summaryd = summaryjson[0].orderSummary;

      if (summaryd != null) {
        //ACCOUNT ID
        const accountjson = await getAccountId(user.Profile);
        var accountD = null;
        accountD = accountjson[0].accountId;

        if (accountD != null) {
          //Mokhatbat Message
          const Message = await postMokhatbat(
            accountD,
            searchParams.get("ConvId"),
            summaryd
          );

          if (Message.ok) {
            window.open(
              `https://api.whatsapp.com/send?phone=${searchParams.get(
                "CustNumber"
              )}&text=`
            );
          } else {
            toast.error("Something went wrong");
          }
        } else {
          toast.error("Something went wrong");
        }
      } else {
        toast.error("Something went wrong");
      }
    } else {
      toast.error(content.DeliveryMessage);
    }
  };

  const [selectedPayment, setselectedPayment] = useState("Cash");
  const [DeliveryType, setDeliveryType] = useState("From Branch");
  const [DeliveryDropDown, setDeliveryDropDown] = useState("");
  const [DeliveryDropDownLabel, setDeliveryDropDownLabel] = useState("");


  const handleSelectDeliveryDd = (value,label) => {
    setDeliveryDropDown(value);
    setDeliveryDropDownLabel(label);
    console.log("value",value,"Label",label)
    
  };
  

  const selectedOptionsClass = "ring-2 ring-red-400 ring-offset-2";

  const loadOptions=async (searchValue,callback) => {
    const response = await fetch(
      `https://telelogx-001-site1.atempurl.com/api/DeliveryCost?BranchId=${user.BranchId}&Profile=${user.Profile}`,
      {
        headers: {
          Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYmYiOjE2OTI2Mjk2MjMsImV4cCI6MTY5MjYzMzIyMywiaWF0IjoxNjkyNjI5NjIzfQ.koblKU4ufEsVYZ0wyRVDSrbcIjAlmR-9VUty3xwzj6I`,
        },

        cache: "no-store",
      }
    );
    const json = await response.json();
    console.log("json",json)
    callback(json.filter((i)=>
    {
      return searchValue.toLowerCase()===""?i:i.deliveryCost.toLowerCase().includes(searchValue);
    }
    ).map(i=>({label:i.deliveryCost , value:i.areaId })))
 
  }
  
  return (
    <div className="mt-16 rounded-lg bg-gray-50 px-4 py-6 sm:p-6 lg:col-span-5 lg:mt-0 lg:p-8">
      <h2 className="text-lg font-medium text-gray-900">
        {content.orderSummery}
      </h2>
      <div className="mt-4">
  <label htmlFor="notes" className="block text-sm font-medium text-gray-700">
    {content.notes}
  </label>
  <div className="mt-1">
    <textarea
      id="notes"
      name="notes"
      rows={3} // Adjust the number of rows as needed
      className="shadow-sm focus:ring-blue focus:border-blue block w-full sm:text-sm border-gray-300 rounded-md"
      placeholder={content.enterNotes}
    />
  </div>
</div>
      {ItemsLength !== 0 && (
        <>
          {/* Cards */}
          <div className=" text-base mt-2">{content.payType}</div>
          <div className="w-full mt-2 grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-3">
            <div
              onClick={() => {
                setselectedPayment("Cash");
              }}
              className={`${
                selectedPayment === "Cash" && selectedOptionsClass
              } bg-white hover:cursor-pointer dark:bg-gray-800 rounded shadow px-5 py-4 flex items-center`}
            >
              <div
                className={`p-3  ${
                  selectedPayment === "Cash"
                    ? "bg-red-600"
                    : "bg-black opacity-50"
                } rounded`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-cash"
                  width={32}
                  height={32}
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke={"#fff"}
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M7 9m0 2a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v6a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2z"></path>
                  <path d="M14 14m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
                  <path d="M17 9v-2a2 2 0 0 0 -2 -2h-10a2 2 0 0 0 -2 2v6a2 2 0 0 0 2 2h2"></path>
                </svg>
              </div>
              <div className="ml-6">
                <h3 className="mb-1 leading-5 text-gray-800 dark:text-gray-100 font-bold text-2xl p-3">
                  {content.cash}
                </h3>
              </div>
            </div>
            <div>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-credit-card"
                  width={32}
                  height={32}
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke={"#fff"}
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M3 5m0 3a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-12a3 3 0 0 1 -3 -3z"></path>
                  <path d="M3 10l18 0"></path>
                  <path d="M7 15l.01 0"></path>
                  <path d="M11 15l2 0"></path>
                </svg>
              </div>
            </div>
          </div>
          {/* Cards */}
          {/* Delivery info */}
          <div className=" text-base mt-2">{content.deliType}</div>
          <div className="flex flex-row">
            <label className="mr-2 p-3">
              <input
                type="radio"
                className="m-1"
                value="From Branch"
                checked={DeliveryType === "From Branch"}
                onChange={() => {setDeliveryType("From Branch") ; setDeliveryDropDown("")}}
              />
              {content.restType}
            </label>
            <label className="mr-2 p-3">
              <input
                type="radio"
                value="Delivery"
                className="m-1"
                checked={DeliveryType === "Delivery"}
                onChange={() => setDeliveryType("Delivery")}
              />
              {content.Delivery}
            </label>
          </div>
          {DeliveryType === "Delivery" && (
            <div className="relative w-full ">
               <AsyncSelect
               required
               value={{ label: DeliveryDropDownLabel, value: DeliveryDropDown }}
               onChange={({value,label}) => handleSelectDeliveryDd(value,label)}               
               loadOptions={loadOptions}  
               placeholder={content.Start}
               defaultOptions={DeliveryInfo.map((delivery) => ({ label: delivery.deliveryCost, value: delivery.areaId }))}
               isSearchable={true}
               />
               
            </div>
          )}

          {/* Delivery info */}
        </>
      )}
      <div className="mt-6 space-y-4">
        {/* {user.Discount ? (
          <>
            {" "}
            <div className="flex items-center justify-between border-t border-gray-200 pt-4">
              <div className="text-base font-medium text-gray-900">
                {content.BeforeDiscount}
              </div>
              <Currency
                value={
                  parseFloat(TotalPrice) / (1 - parseInt(user.Discount) / 100)
                }
              />
            </div>
            <div className="flex items-center justify-between border-gray-200">
              <div className="text-base font-medium text-gray-900">
                {content.Discount}
              </div>
              <div className=" font-bold text-base text-red-600">
                {parseInt(user.Discount) + "%"}
              </div>
            </div>
          </>
        ) : (
          <></>
        )} */}

        <div className="flex items-center justify-between border-t border-gray-200 pt-4">
          <div className="text-base font-medium text-gray-900">
            {content.total}
          </div>
          <Currency value={TotalPrice} />
        </div>
      </div>
      <button
        onClick={onCheckout}
        disabled={ItemsLength === 0}
        className={`w-full mt-6
        rounded-full 
        bg-black
        border
        border-transparent
        px-5 
        py-3 
        disabled:cursor-not-allowed 
        disabled:opacity-50
        text-white
        font-semibold
        hover:opacity-75
        transition ${ItemsLength === 0 && "opacity-75 cursor-not-allowed"}`}
      >
        {content.checkout}
      </button>
    </div>
  );
};

export default Summary;
