import React, { useContext, useState, useEffect } from "react";
import Currency from "./currency";
import { AppContext } from "../AppContext";
import { ShoppingCart } from "lucide-react";
import postOrder from "../api/postOrder";
import toast from "react-hot-toast";
import Translate from "./Translate.json";

const Info = ({ data, additives }) => {
  const { user } = useContext(AppContext);
  const [quantity, setQuantity] = useState(1);
  const [selectedOptions, setSelectedOptions] = useState();
  const [language, setLanguage] = useState(user.Language);
  const [content, setContent] = useState(Translate.en);

  useEffect(() => {
    if (language == "ar") {
      setContent(Translate.ar);

    } else if (language == "en") {
      setContent(Translate.en);
    }
  }, []);
  const onAddToCart = async (event) => {
    event.stopPropagation();
    const orderDetails = {
      profile: user.Profile,
      qty: quantity,
      note: "",
      name: user.CustomerName,
      mobile: user.Mobile,
      branchId: user.BranchId,
      mealsId: data.subMealID,
      additivesId: selectedOptions ? selectedOptions.additivesID : "0",
      price: data.subMealPrice,
      //newPrice: data.subMealNewPrice,
      orderDetailsId: "",
      mealName: data.subMealAR,
      insertedAdditivesId: "",
      additivePrice: "",
      additivesName: "",
    };
    const Order = await postOrder(orderDetails);

    if (Order.ok) {
      toast.success("Item added to cart.");
    } else {
      toast.error("Something went wrong");
    }
    // cart.addItem(data);
  };

  const handleAdditiveChange = (additive) => {
    if (selectedOptions?.additivesID === additive.additivesID) {
      setSelectedOptions(undefined);
    } else {
      setSelectedOptions(additive);
    }
  };

  return (
    <div>
      <h1 className="text-3xl font-bold text-gray-900">{data.subMealAR}</h1>
      <div className="mt-3 flex items-center">
        <div className="mr-2 text-2xl font-semibold text-gray-900  ">
          <Currency
            value={data.subMealPrice}
          />
        </div>
        {/* { data.subMealNewPrice !== "" ? (
          <>
            <p className="text-base  font-medium text-red-500 line-through dark:text-red-300">
              <Currency value={data?.subMealPrice} />
            </p>
            <p className=" ml-2 text-base font-medium text-green-500">
              {(
                ((Number(data.subMealPrice) - Number(data.subMealNewPrice)) /
                  Number(data.subMealPrice)) *
                100
              ).toFixed(2) + "%"}
            </p>
          </>
        ) : (
          <></>
        )} */}
      </div>
      <hr className="my-4" />
      <div className="flex flex-col gap-y-6">
        <div className="flex items-center gap-x-4">
          <h3 className="font-semibold text-black">{content.category}</h3>
          <div>{data?.mainMeal.mainMealAR}</div>
        </div>
        <div className="flex items-center gap-x-4">
          <h3 className="font-semibold text-black">{content.description}</h3>
          <div>{data?.description}</div>
        </div>
        {/* QUANTITY */}
        <div className="w-full bg-slate-100 rounded-lg h-10 mb-4 flex items-center justify-between px-6 lg:px-3 font-bold sm:mr-3 lg:mr-5 lg:w-1/4">
          <button
            onClick={() => {
              if (quantity > 1) {
                setQuantity(quantity - 1);
              }
            }}
            className=" text-orange-700 text-2xl leading-none font-bold mb-1 lg:mb-2 lg:text-3xl hover:opacity-60"
          >
            -
          </button>
          <div className="text-slate-950 bg-slate-100 rounded-lg font-bold justify-center flex text-center w-full">
            {quantity}
          </div>
          <button
            onClick={() => {
              if (quantity < 100) {
                setQuantity(quantity + 1);
              }
            }}
            className="text-orange-700 text-2xl leading-none font-bold mb-1 lg:mb-2 lg:text-3xl hover:opacity-60"
          >
            +
          </button>
        </div>
        {/* QUANTITY */}
      </div>
      <div className=" grid grid-cols-1 lg:grid-cols-2 gap-3 ">
        {additives.map((item) => (
          <label className="cursor-pointer" key={item.additivesID}>
            <input
              type="checkbox"
              className="peer sr-only"
              name={selectedOptions?.addvName}
              // checked={selectedOptions.includes(item.additivesID)}
              checked={selectedOptions?.additivesID === item.additivesID}
              onChange={() => {
                handleAdditiveChange(item);
              }}
            />
            <div className=" w-full rounded-md p-3 text-gray-600 ring-2 ring-slate-100 transition-all shadow-lg  peer-checked:text-red-600 peer-checked:ring-red-400 peer-checked:ring-offset-2">
              <div className="flex flex-col gap-1">
                <div className="flex items-center justify-between">
                  <p className=" font-semibold uppercase text-gray-500">
                    {item.addvName}
                  </p>
                  <div>
                    <svg width="24" height="24" viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="m10.6 13.8l-2.175-2.175q-.275-.275-.675-.275t-.7.3q-.275.275-.275.7q0 .425.275.7L9.9 15.9q.275.275.7.275q.425 0 .7-.275l5.675-5.675q.275-.275.275-.675t-.3-.7q-.275-.275-.7-.275q-.425 0-.7.275ZM12 22q-2.075 0-3.9-.788q-1.825-.787-3.175-2.137q-1.35-1.35-2.137-3.175Q2 14.075 2 12t.788-3.9q.787-1.825 2.137-3.175q1.35-1.35 3.175-2.138Q9.925 2 12 2t3.9.787q1.825.788 3.175 2.138q1.35 1.35 2.137 3.175Q22 9.925 22 12t-.788 3.9q-.787 1.825-2.137 3.175q-1.35 1.35-3.175 2.137Q14.075 22 12 22Z"
                      />
                    </svg>
                  </div>
                </div>
                <div className="flex items-end justify-between">
                  <p>
                    <span className="text-lg font-bold">
                      {item.addvPrice} JOD
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </label>
        ))}
      </div>
      <div className="mt-10 flex items-center gap-x-3">
        <button
          onClick={onAddToCart}
          className="flex items-center gap-x-2 bg-red-600 rounded-xl shadow-red-300 shadow-2xl w-auto 
          border
          border-transparent
          px-5 
          py-3 
          disabled:cursor-not-allowed 
          disabled:opacity-50
          text-white
          font-semibold
          hover:opacity-75
          transition"
        >
          {content.addCart}
          <ShoppingCart size={20} />
        </button>
      </div>
    </div>
  );
};

export default Info;
