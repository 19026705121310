import React, { useContext, useEffect, useState } from "react";
import { ShoppingBag } from "lucide-react";
import { ShoppingCart } from "react-feather";
import { AppContext } from "../AppContext";
import { useNavigate } from "react-router-dom";

const NavbarActions = () => {
  const navigate = useNavigate();
  const { user } = useContext(AppContext);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  if (!isMounted) {
    return null;
  }

  return (
    <div className="ml-auto flex items-center gap-x-4">
      <button
        onClick={() => {
          navigate(
            `/cart?Profile=${user.Profile}&BrId=${user.BranchId}&MobNo=${user.Mobile}&CusName=${user.CustomerName}&Lang=${user.Language}&CustNumber=${user.CustomerNumber}&ConvId=${user.ConvId}`
          );
        }}
        className="        w-auto 
        border
        border-transparent
        disabled:cursor-not-allowed 
        disabled:opacity-50
        text-white
        font-semibold
        hover:opacity-75
        transition flex items-center rounded-full bg-red-700 px-4 py-2"
      >
        <ShoppingCart size={20} color="white" />
        {/* <span className="ml-2 text-sm font-medium text-white">
          {cart.length}
        </span> */}
      </button>
    </div>
  );
};

export default NavbarActions;
