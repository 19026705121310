import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../AppContext";
import Container from "../components/Container";
import getCart from "../api/getCart";
import CartItem from "../components/CartItem";
import Summary from "../components/summary";
import Translate from "./Translate.json";
import NotFound from "./NotFound";

const Cart = () => {
  const { user } = useContext(AppContext);
  const [isMounted, setIsMounted] = useState(false);
  const [cartimesr, setCart] = useState();
  const [language, setLanguage] = useState(user.Language);
  const [content, setContent] = useState(Translate.en);
  const [hasError, setHasError] = useState(false);

  const fetchCart = async () => {
    try {
      const carti = await getCart(
        user.Profile,
        user.BranchId,
        user.Mobile,
        user.Language
      );
      if (carti !== false) {
        setCart(carti);
      } else {
        setHasError(true);
      }
    } catch (error) {
      setHasError(true);
    }
  };

  useEffect(() => {
    if (language === "ar") {
      setContent(Translate.ar);
    } else if (language === "en") {
      setContent(Translate.en);
    }
  }, []);
  useEffect(() => {
    setIsMounted(true);
    fetchCart();
  }, []);
  if (!isMounted) {
    return null;
  }
  if (hasError === true) {
    console.log("Helllo");
    return <NotFound />;
  }
  return (
    <div className="bg-white">
      <Container>
        <div className="px-4 py-16 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold text-black">{content.cartTitle}</h1>
          <div className="mt-12 lg:grid lg:grid-cols-12 lg:items-start gap-x-12">
            <div className="lg:col-span-7">
              {cartimesr?.ordt_OrdersId === null ? (
                <p className="text-neutral-500">{"No items added to cart."}</p>
              ) : (
                <ul>
                  {cartimesr?.details.map((item) => (
                    <CartItem
                      key={item.ordt_OrderDetails}
                      data={item}
                      cartFunction={() => {
                        fetchCart();
                      }}
                    />
                  ))}
                </ul>
              )}
            </div>
            {cartimesr?.ordt_OrdersId === null ? (
              <div></div>
            ) : (
              <Summary
                TotalPrice={cartimesr?.ordr_Price || ""}
                ItemsLength={cartimesr?.details.length || 0}
                OrderID={cartimesr?.ordt_OrdersId || ""}
                cartFunction={() => {
                  fetchCart();
                }}
              />
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Cart;
