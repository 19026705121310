function getAdditives(Profile, MainMealId) {
  const apiUrl = `https://telelogx-001-site1.atempurl.com/api/Additives?ProfileID=${Profile}&MainMealId=${MainMealId}`;
  return fetch(apiUrl, {
    headers: {
      Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYmYiOjE2OTI2Mjk2MjMsImV4cCI6MTY5MjYzMzIyMywiaWF0IjoxNjkyNjI5NjIzfQ.koblKU4ufEsVYZ0wyRVDSrbcIjAlmR-9VUty3xwzj6I`,
    },
    cache: "no-store",
  })
    .then((response) => {
      // Check if the response status is OK (status code 200)
      if (!response.ok) {
        return false;
      }
      // Parse the response as JSON and return it as a promise
      return response.json();
    })
    .then((data) => {
      // The data variable now contains the parsed JSON response
      return data;
    })
    .catch((error) => {
      return false;
    });
}
export default getAdditives;
