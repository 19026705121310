const removeItem = async (data) => {
  try {
    const response = await fetch(
      `https://telelogx-001-site1.atempurl.com/api/Cart/delteItem`,
      {
        cache: "no-store",
        method: "POST",
        headers: {
          Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYmYiOjE2OTI2Mjk2MjMsImV4cCI6MTY5MjYzMzIyMywiaWF0IjoxNjkyNjI5NjIzfQ.koblKU4ufEsVYZ0wyRVDSrbcIjAlmR-9VUty3xwzj6I`,

          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          itemID: data.ordt_OrderDetails,
        }),
      }
    );
    return response.ok;
  } catch (error) {
    return false;
  }
};
export default removeItem;
