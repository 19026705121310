import React, { useContext, useEffect, useState } from "react";
import Container from "../components/Container";
import { AppContext } from "../AppContext";
import getBillboard from "../api/getBillboard";
import Billboard from "../components/billboard";
import ProductList from "../components/product-list";
import getProducts from "../api/getProducts";
import { useNavigate } from "react-router-dom";
import NotFound from "./NotFound";

const Home = () => {
  const { user } = useContext(AppContext);
  const [billboard, setBillboard] = useState();
  const [loadingBillboard, setLoadingBillboard] = useState(true);
  const [loadingProduct, setLoadingProduct] = useState(true);
  const [products, setProducts] = useState();
  const navigate = useNavigate();
  const [hasError, setHasError] = useState(false);

  
  if (user.Language=="ar"){
    document.getElementsByTagName('html')[0].setAttribute("dir", "rtl");
   
  } else if (user.Language=="en") {
      document.getElementsByTagName('html')[0].setAttribute("dir", "ltr");
    }

   useEffect(() => {
    try {
      getProducts(user.Profile, user.BranchId, user.Language)
        .then((productjson) => {
          if (productjson !== false) {
            setProducts(productjson);
            setLoadingProduct(false);
          } else {
            setHasError(true);
          }
        })
        .catch(() => {
          setHasError(true);
        });
      getBillboard(user.Profile)
        .then((billboardjson) => {
          if (billboardjson !== false) {
            setBillboard(billboardjson);
            setLoadingBillboard(false);
          } else {
            setHasError(true);
          }
        })
        .catch(() => {
          setHasError(true);
        });
    } catch (error) {
      setHasError(true);
    }
  }, [user.Profile, user.BranchId, user.Language]);

  if (hasError === true) return <NotFound />;
  return (
    <Container>
      {loadingBillboard ? (
        <div className="p-4 sm:p-8 lg:p-8 rounded-xl   overflow-hidden">
          <div className="rounded-xl relative aspect-square bg-slate-100 animate-pulse md:aspect-[2.7/1] overflow-hidden bg-cover"></div>
        </div>
      ) : (
        <div className="space-y-10 pb-10">
          <Billboard data={billboard} />
          {/* <div className="flex flex-col gap-y-8 px-4 sm:px-6 lg:px-8">
            {!loadingProduct && (
              <ProductList title="Meals - الوجبات" items={products} />
            )}
          </div> */}
        </div>
      )}
    </Container>
  );
};

export default Home;
