import React, { useContext, useEffect, useState } from "react";
import Container from "../components/Container";
import ProductList from "../components/product-list";
import Gallery from "../components/Gallery";
import getProductsById from "../api/getProductById";
import { AppContext } from "../AppContext";
import getProductsByCategory from "../api/getProductsByCategory";
import { useParams } from "react-router-dom";
import Info from "../components/Info";
import getAdditives from "../api/getAdditives";
import NotFound from "./NotFound";

const Product = () => {
  const params = useParams();
  const productId = params.productId;
  const { user } = useContext(AppContext);
  const [product, setProduct] = useState();
  const [loadingProduct, setLoadingProduct] = useState(true);
  const [additives, setadditives] = useState();
  const [loadingadditives, setLoadingadditives] = useState(true);
  const [suggestedProducts, setSuggestedProducts] = useState();
  const [loadingSuggestedProducts, setLoadingSuggestedProducts] =
    useState(true);
  const [hasError, setHasError] = useState(false);
  useEffect(() => {
    try {
      getProductsById(
        productId,
        user.Profile,
        user.BranchId,
        user.Language
      ).then((productjson) => {
        if (productjson !== false) {
          setProduct(productjson[0]);
          setLoadingProduct(false);

          //   Get suggested from product category
          getProductsByCategory(
            user.Profile,
            user.BranchId,
            user.Language,
            productjson[0].mainMeal.mainMealID
          ).then((suggestedProductjson) => {
            if (suggestedProductjson !== false) {
              setSuggestedProducts(suggestedProductjson);
              setLoadingSuggestedProducts(false);
            } else {
              setHasError(true);
            }
          });

          // Get Addtives of the product
          getAdditives(user.Profile, productjson[0].mainMeal.mainMealID).then(
            (additivesjson) => {
              if (additivesjson !== false) {
                setadditives(additivesjson);
                setLoadingadditives(false);
              } else {
                setHasError(true);
              }
            }
          );
        } else {
          setHasError(true);
        }
      });
    } catch (error) {
      setHasError(true);
    }
  }, [productId, user.BranchId, user.Language, user.Profile]);
  if (hasError === true) {
    return <NotFound />;
  }
  return (
    <div className=" bg-white">
      <Container>
        <div className=" px-4 py-10 sm:px-6 lg:px-8">
          <div className="lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8">
            {/* Gallery */}
            {!loadingProduct && (
              <Gallery image={product.subMealImgPath.replace(/^~/, "")} />
            )}
            <div className="mt=10 px-4 sm:mt-16 sm:px-0 lg:mt-0">
              {loadingProduct === false && loadingadditives === false ? (
                <Info data={product} additives={additives} />
              ) : (
                <></>
              )}
            </div>
          </div>
          <hr className="my-10 "></hr>
          {!loadingSuggestedProducts && (
            <ProductList
              title={"Related Items - ذات صلة"}
              items={suggestedProducts.slice(0, 4)}
            ></ProductList>
          )}
        </div>
      </Container>
    </div>
  );
};

export default Product;
