import React from "react";
import Currency from "../components/currency";
import removeItem from "../api/removeitem";
import toast from "react-hot-toast";
import { X } from "lucide-react";

const CartItem = ({ data, cartFunction }) => {
  const onRemove = async () => {
    const response = await removeItem(data);
    if (response) {
      toast.success("Item removed successfully.");
      cartFunction();
    } else {
      toast.error("Something went wrong.");
    }
  };

  return (
    <li className="flex py-6 border-b">
      <div className="relative h-24 w-24 rounded-md overflow-hidden sm:h-48 sm:w-48">
        <img
          src={data.subMeal.images}
          alt=""
          className="object-cover h-full w-full object-center"
        />
        
      </div>
      <button
            className="rounded-full h-8 ml-4 mr-4 flex items-center justify-center bg-white border shadow-md p-2 hover:scale-110 transition"
            onClick={onRemove}
          >
            <X size={15} />
      </button>
      <div className="relative ml-4 flex flex-1 flex-col justify-between sm:ml-6">
        <div className="relative pr-9 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:pr-0">
          <div className="flex justify-between">
            <p className=" text-lg font-semibold text-black">
              {data.subMeal.name}
            </p>
          </div>

          <div className="mt-1 flex text-sm">
            <p className="text-gray-500">Category - الصنف :</p>
            <p className="ml-4  text-gray-500">
              {data.subMeal.category.name}
            </p>
          </div>
          <div className="flex justify-between">
            <p className=" text-sm font-medium text-gray-400">
              {data.subMeal.description}
            </p>
          </div>
          <div className="mt-1 flex text-sm">
            <p className="text-gray-500">Quantity - العدد :</p>
            <p className="ml-4  text-gray-500">
              {Number(data.ordt_Qty).toFixed(0)}
            </p>
          </div>
          <div></div>
          <div>
            <Currency value={data.ordt_Price} />
          </div>
        </div>
      </div>
    </li>
  );
};

export default CartItem;
