import React, { useState, useEffect, useContext } from "react";
import Billboard from "../components/billboard";
import getBillboard from "../api/getBillboard";
import { AppContext } from "../AppContext";
import ProductCard from "../components/productCard";
import Container from "../components/Container";
import NoResults from "../components/noResult";
import getProductsByCategory from "../api/getProductsByCategory";
import { useParams } from "react-router-dom";
import NotFound from "./NotFound";

const Category = () => {
  const { user } = useContext(AppContext);
  const params = useParams();
  const categoryId = params.categoryId;
  const [billboard, setBillboard] = useState();
  const [loadingBillboard, setLoadingBillboard] = useState(true);
  const [products, setProducts] = useState();
  const [loadingProduct, setLoadingProduct] = useState(true);
  const [hasError, setHasError] = useState(false);
  useEffect(() => {
    try {
      getProductsByCategory(
        user.Profile,
        user.BranchId,
        user.Language,
        categoryId
      ).then((productjson) => {
        if (productjson !== false) {
          setProducts(productjson);
          setLoadingProduct(false);
        } else {
          setHasError(true);
        }
      });
      getBillboard(user.Profile).then((billboardjson) => {
        if (billboardjson !== false) {
          setBillboard(billboardjson);
          setLoadingBillboard(false);
        } else {
          setHasError(true);
        }
      });
    } catch (error) {
      setHasError(true);
    }
  }, [user.Profile, user.BranchId, user.Language, categoryId]);

  if (hasError === true) {
    return <NotFound />;
  }

  return (
    <div className="bg-white">
      <Container>
        {loadingBillboard ? <div></div> : <Billboard data={billboard} />}
        <div className="px-4 sm:px-6 lg:px-8 pb-24">
          <div className="lg:grid lg:grid-cols-5 lg:gap-x-8">
            {/* <MobileFilters sizes={[]} /> */}
            {/* <div className="hidden lg:block">
              <Filter valueKey="filter" name="Filters - تصنيفات" data={[]} />
            </div> */}
            <div className="mt-6 lg:col-span-5 lg:mt-0">
              {loadingProduct ? (
                <div></div>
              ) : (
                <>
                  {products.length === 0 && <NoResults />}
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
                    {products.map((item) => (
                      <ProductCard key={item.subMealID} data={item} />
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Category;
