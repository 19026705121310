function getOrderSummary(Profile, MobileNo,BranchId) {
    const apiUrl = `https://telelogx-001-site1.atempurl.com/api/OrderSummary?ProfileName=${Profile}&BranchId=${BranchId}&MobileNo=${MobileNo}`;
    return fetch(apiUrl, {
      headers: {
        Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYmYiOjE2OTI2Mjk2MjMsImV4cCI6MTY5MjYzMzIyMywiaWF0IjoxNjkyNjI5NjIzfQ.koblKU4ufEsVYZ0wyRVDSrbcIjAlmR-9VUty3xwzj6I`,
      },
      cache: "no-store",
    })
      .then((response) => {
        // Check if the response status is OK (status code 200)
        if (!response.ok) {
          throw new Error(`Error fetching Summary: ${response.statusText}`);
        }
        // Parse the response as JSON and return it as a promise
        return response.json();
      })
      .then((data) => {
        // The data variable now contains the parsed JSON response
        return data;
      })
      .catch((error) => {
        // Handle any errors that occurred during the fetch
        console.error(error);
        throw error; // Rethrow the error so it can be handled by the caller
      });
  }
  export default getOrderSummary;
  