function getBillboard(Profile) {
  const apiUrl = `https://telelogx-001-site1.atempurl.com/getProfile?Profile=${Profile}`;
  return fetch(apiUrl, {
    headers: {
      Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYmYiOjE2OTI2Mjk2MjMsImV4cCI6MTY5MjYzMzIyMywiaWF0IjoxNjkyNjI5NjIzfQ.koblKU4ufEsVYZ0wyRVDSrbcIjAlmR-9VUty3xwzj6I`,
    },
    cache: "no-store",
  })
    .then((response) => {
      if (!response.ok) {
        return false;
      }
      return response.json();
    })
    .then((data) => {
      if (data.length === 0) return false;
      return data;
    })
    .catch(() => {
      return false;
    });
}
export default getBillboard;
