import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Container from "./Container";
import { AppContext } from "../AppContext";
import MainNav from "./mainNav";
import getCategories from "../api/getCategories";
import NavbarActions from "./NavActions";

const Navbar = () => {
  const { user } = useContext(AppContext);
  const [categories, setCategories] = useState([]);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    getCategories(user.Profile, user.Language, user.BranchId).then(
      (categoriesData) => {
        if (categoriesData !== false) {
          setCategories(categoriesData);
        } else {
          setHasError(true);
        }
      }
    );
  }, [user.Profile, user.Language, user.BranchId]);

  if (hasError === true) return null;

  return (
    <div className="border-b">
      <Container>
        <div className="flex items-center justify-between">
          <Link
            to={`/?Profile=${user.Profile}&BrId=${user.BranchId}&MobNo=${user.Mobile}&CusName=${user.CustomerName}&Lang=${user.Language}&CustNumber=${user.CustomerNumber}&ConvId=${user.ConvId}`}
            className="flex items-center gap-x-2"
          >
            <p className="font-bold text-xl ml-4">{user.Profile}</p>
          </Link>
          <NavbarActions />
        </div>
      </Container>
      <Container>
        <div className="px-4 sm:px-6 lg:px-8">
          <MainNav user={user} data={categories}></MainNav>
        </div>
      </Container>
    </div>
  );
};

export default Navbar;
