import React from "react";

const Gallery = ({ image }) => {
  return (
    <div className="aspect-square w-80">
      <div className="aspect-square relative h-full w-full sm:rounded-lg overflow-hidden">
        <img
          src={image}
          alt="Product"
          className="object-cover w-full h-full object-center"
        />
      </div>
    </div>
  );
};

export default Gallery;
