import React, { useContext } from "react";
import { AppContext } from "../AppContext";
import { useNavigate } from "react-router-dom";
import { Expand, ShoppingCart } from "lucide-react";
import Currency from "./currency";
import toast from "react-hot-toast";
import postOrder from "../api/postOrder";

const ProductCard = ({ data }) => {
  const { user } = useContext(AppContext);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(
      `/product/${data.subMealID}?Profile=${user.Profile}&BrId=${user.BranchId}&MobNo=${user.Mobile}&CusName=${user.CustomerName}&Lang=${user.Language}&CustNumber=${user.CustomerNumber}&ConvId=${user.ConvId}`
    );
  };
  const onAddToCart = async (event) => {
    event.stopPropagation();
    const orderDetails = {
      profile: user.Profile,
      qty: 1,
      note: "",
      name: user.CustomerName,
      mobile: user.Mobile,
      branchId: user.BranchId,
      mealsId: data.subMealID,
      additivesId: "0",
      price: data.subMealPrice,
      //newPrice: data.subMealNewPrice,
      orderDetailsId: "",
      mealName: data.subMealAR,
      insertedAdditivesId: "",
      additivePrice: "",
      additivesName: "",
    };
    const Order = await postOrder(orderDetails);

    if (Order.ok) {
      toast.success("Item added to cart.");
    } else {
      toast.error("Something went wrong");
    }
    //cart.addItem(data);
  };
  return (
    <div
      onClick={handleClick}
      className="bg-white group cursor-pointer rounded-xl border p-3 space-y-4"
    >
      {/* Image */}
      <div className="spect-square rounded-xl bg-gray-100 relative">
        <img
          alt="img"
          // src="/SubMealsIMG/Telelogx/تبولة.jpg"
          src={data.subMealImgPath.replace(/^~/, "")}
          className="aspect-square object-cover rounded-md"
        />
        <div className="opacity-0 group-hover:opacity-100 transition absolute w-full px-6 bottom-5">
          <div className="flex gap-x-6 justify-center">
            <button className="rounded-full flex items-center justify-center bg-white border shadow-md p-2 hover:scale-110 transition">
              <Expand size={20} className="text-gray-600" />
            </button>
            <button
              onClick={onAddToCart}
              className="rounded-full flex items-center justify-center bg-white border shadow-md p-2 hover:scale-110 transition"
            >
              <ShoppingCart size={20} className="text-gray-600" />
            </button>
          </div>
        </div>
      </div>
      {/* Image */}
      {/*Description*/}
      <div>
        <div className="font-semibold text-lg">{data.subMealAR}</div>
        <div className="text-sm text-gray-500">
          {data.description.slice(0, 50) + "..."}
        </div>
      </div>
      {/* Description */}
      <div className="flex items-center">
        {/* Currency */}
        <div className="mr-2 text-2xl font-semibold text-gray-900  ">
          <Currency
            value={data.subMealPrice}
          />
        </div>
        {/* Discount */}
        {/* {data.subMealNewPrice !== "" ? (
          <>
            <p className="text-base  font-medium text-red-500 line-through dark:text-red-300">
              <Currency value={data?.subMealPrice} />
            </p>
            <p className=" ml-2 text-base font-medium text-green-500">
              {(
                ((Number(data.subMealPrice) - Number(data.subMealNewPrice)) /
                  Number(data.subMealPrice)) *
                100
              ).toFixed(2) + "%"}
            </p>
          </>
        ) : (
          <></>
        )} */}
        {/* Currency */}
      </div>
    </div>
  );
};

export default ProductCard;
